@keyframes dtb-spinner {
  100% {
    transform: rotate(360deg);
  }
}
@-o-keyframes dtb-spinner {
  100% {
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-ms-keyframes dtb-spinner {
  100% {
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes dtb-spinner {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes dtb-spinner {
  100% {
    -moz-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
div.dt-button-info {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 400px;
  margin-top: -100px;
  margin-left: -200px;
  background-color: $lighter;
  box-shadow: 0 0 0 200vh rgba($dark,.3);
  border-radius: $border-radius;
  text-align: center;
  z-index: 2020;
  overflow: hidden;
  border: 1px solid $border-light;
}
div.dt-button-info h2 {
  padding: 2rem;
  margin: 0;
  font-weight: normal;
  border-bottom: 1px solid $border-light;
  background-color: $white;
  font-size: $h2-fs;
}
div.dt-button-info > div {
  padding: 1.25rem;
}

div.dt-button-collection-title {
  text-align: center;
  padding: 0.3em 0 0.5em;
  font-size: 0.9em;
}

div.dt-button-collection-title:empty {
  display: none;
}

div.dt-button-collection {
  position: absolute;
  z-index: $zindex-dropdown;
  width: 100%;
}
div.dt-button-collection div.dropdown-menu {
  display: block;
  z-index: $zindex-dropdown + 1;
  min-width: 100px;
  top: 10px;
  .dropdown-position-right &{
    right: 0;
    left: auto;
  }
}
div.dt-button-collection div.dt-button-collection-title {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.15);
}
div.dt-button-collection.fixed {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -75px;
  border-radius: 0;
}
div.dt-button-collection.fixed.two-column {
  margin-left: -200px;
}
div.dt-button-collection.fixed.three-column {
  margin-left: -225px;
}
div.dt-button-collection.fixed.four-column {
  margin-left: -300px;
}
div.dt-button-collection > :last-child {
  display: block !important;
  -webkit-column-gap: 8px;
  -moz-column-gap: 8px;
  -ms-column-gap: 8px;
  -o-column-gap: 8px;
  column-gap: 8px;
}
div.dt-button-collection > :last-child > * {
  -webkit-column-break-inside: avoid;
  break-inside: avoid;
}
div.dt-button-collection.two-column {
  width: 400px;
}
div.dt-button-collection.two-column > :last-child {
  padding-bottom: 1px;
  -webkit-column-count: 2;
  -moz-column-count: 2;
  -ms-column-count: 2;
  -o-column-count: 2;
  column-count: 2;
}
div.dt-button-collection.three-column {
  width: 450px;
}
div.dt-button-collection.three-column > :last-child {
  padding-bottom: 1px;
  -webkit-column-count: 3;
  -moz-column-count: 3;
  -ms-column-count: 3;
  -o-column-count: 3;
  column-count: 3;
}
div.dt-button-collection.four-column {
  width: 600px;
}
div.dt-button-collection.four-column > :last-child {
  padding-bottom: 1px;
  -webkit-column-count: 4;
  -moz-column-count: 4;
  -ms-column-count: 4;
  -o-column-count: 4;
  column-count: 4;
}
div.dt-button-collection .dt-button {
  border-radius: 0;
}
div.dt-button-collection.fixed {
  max-width: none;
}
div.dt-button-collection.fixed:before, div.dt-button-collection.fixed:after {
  display: none;
}

div.dt-button-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: $zindex-dropdown;
  background-color: rgba($dark,.3);
  display: none !important;
}

@media screen and (max-width: 767px) {
  div.dt-buttons {
    float: none;
    width: 100%;
    text-align: center;
    margin-bottom: 0.5em;
  }
  div.dt-buttons a.btn {
    float: none;
  }
}
div.dt-buttons button.btn.processing,
div.dt-buttons div.btn.processing,
div.dt-buttons a.btn.processing {
  color: rgba(0, 0, 0, 0.2);
}
div.dt-buttons button.btn.processing:after,
div.dt-buttons div.btn.processing:after,
div.dt-buttons a.btn.processing:after {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 16px;
  height: 16px;
  margin: -8px 0 0 -8px;
  box-sizing: border-box;
  display: block;
  content: " ";
  border: 2px solid #282828;
  border-radius: 50%;
  border-left-color: transparent;
  border-right-color: transparent;
  animation: dtb-spinner 1500ms infinite linear;
  -o-animation: dtb-spinner 1500ms infinite linear;
  -ms-animation: dtb-spinner 1500ms infinite linear;
  -webkit-animation: dtb-spinner 1500ms infinite linear;
  -moz-animation: dtb-spinner 1500ms infinite linear;
}

.dt-buttons .btn-secondary{
  @extend .btn-outline-light;
  @extend .btn-white;
  @extend .btn-icon;
  span{
    display: none;
  }
  &:before{
    font-size: 1.125rem;
    font-weight: normal;
    font-style: normal;
    width: $btn-line-height + $btn-padding-y*2;
    font-family: $nk-dashlite-font;
  }
}
.dt-export{
  &-title{
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
}
.buttons{
  &-copy:before{
    content: $ni-copy;
  }
  &-pdf:before{
    content: $ni-file-pdf;
  }
  &-excel:before{
    content: $ni-file-xls;
  }
  &-csv:before{
    content: $ni-file-text;
  }
  &-print:before{
    content: $ni-printer;
  }
  &-colvis:before{
    content: $ni-view-col-sq;
  }
  &-columnVisibility{
    position: relative;
    padding-right: $dropdown-item-padding-x*3;
    font-size: $fx-sz-12;
    font-weight: $fw-medium;
    color: $base-text;
    transition: all .4s;
    &.active{
      &:not(:hover){
        color: $base-text;
        background-color: transparent;
      }
      &:after{
        content: $ni-check;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 1rem;
        font-family: $nk-dashlite-font;
      }
    }
  }
}

@media (max-width: 600px) {
  .with-export {
    > .col-5, > .col-7, > .col-sm-4, > .col-sm-8 {
      width: 100% !important;
      flex: 0 0 100%;
      max-width: 100%;
    }
    .datatable-filter .justify-content-end {
      justify-content: space-between !important;
    }
    .dataTables_filter label {
      display: block;
    }
    .dataTables_filter .form-control {
      width: 100% !important;
    }
  }
}