@mixin text-color-class($class, $color) {
    #{$class} {
      color: $color !important;
    }
    @if $emphasized-link-hover-darken-percentage != 0 {
      a#{$class} {
        &:hover,&:focus {
          color: darken($color, $emphasized-link-hover-darken-percentage) !important;
        }
      }
    }
}
.text{
  &-soft{
    color: $base-light !important;
  }
  &-base{
    color: $base-text !important;
  }
  &-head{
    color: $base-color !important;
  }
}
