/// Misc
//////////////////////////

.clipboard-success-message{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $success;
    background: rgba($white,.9);
    border-radius: $border-radius;
    opacity: 0;
    pointer-events: none;
    transition: opacity .3s;
    .clipboard-success &{
        opacity: 1;
    }
}