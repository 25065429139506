/** nouislider - 14.1.1 - 12/15/2019 | These styles are required for noUiSlider to function. */
 .noUi-target,
 .noUi-target * {
   -webkit-touch-callout: none;
   -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
   -webkit-user-select: none;
   -ms-touch-action: none;
   touch-action: none;
   -ms-user-select: none;
   -moz-user-select: none;
   user-select: none;
   -moz-box-sizing: border-box;
   box-sizing: border-box;
 }
 .noUi-target {
   position: relative;
 }
 .noUi-base,
 .noUi-connects {
   width: 100%;
   height: 100%;
   position: relative;
   z-index: 1;
 }
 /* Wrapper for all connect elements.
  */
 .noUi-connects {
   overflow: hidden;
   z-index: 0;
 }
 .noUi-connect,
 .noUi-origin {
   will-change: transform;
   position: absolute;
   z-index: 1;
   top: 0;
   right: 0;
   -ms-transform-origin: 0 0;
   -webkit-transform-origin: 0 0;
   -webkit-transform-style: preserve-3d;
   transform-origin: 0 0;
   transform-style: flat;
 }
 .noUi-connect {
   height: 100%;
   width: 100%;
 }
 .noUi-origin {
   height: 10%;
   width: 10%;
 }
 /* Offset direction
  */
//  .noUi-txt-dir-rtl.noUi-horizontal .noUi-origin {
//    left: 0;
//    right: auto;
//  }
 /* Give origins 0 height/width so they don't interfere with clicking the
  * connect elements.
  */
 .noUi-vertical .noUi-origin {
   width: 100%;
 }
 .noUi-horizontal .noUi-origin {
   height: 100%;
 }
 .noUi-handle {
   -webkit-backface-visibility: hidden;
   backface-visibility: hidden;
   position: absolute;
   &:focus{
    outline: none;
  }
 }
 .noUi-touch-area {
   height: 100%;
   width: 100%;
   &:focus{
     outline: none;
   }
 }
 .noUi-state-tap .noUi-connect,
 .noUi-state-tap .noUi-origin {
   -webkit-transition: transform 0.3s;
   transition: transform 0.3s;
 }
 .noUi-state-drag * {
   cursor: inherit !important;
 }
 /* Slider size and handle placement;
  */
 .noUi-horizontal {
   height: 8px;
 }
 .noUi-horizontal .noUi-handle {
   width: 28px;
   height: 28px;
   right: -14px;
   top: 50%;
   transform: translateY(-50%);
 }
 .noUi-vertical {
   width: 8px;
   height: 200px;
 }
 .noUi-vertical .noUi-handle {
  width: 28px;
  height: 28px;
   left: 50%;
   top: -14px;
   transform: translateX(-50%);
 }
//  .noUi-txt-dir-rtl.noUi-horizontal .noUi-handle {
//    left: -14px;
//    right: auto;
//  }
 /* Styling;
  * Giving the connect element a border radius causes issues with using transform: scale
  */
 .noUi-target {
   background: $border-color;
   border-radius: 4px;
 }
 .noUi-connects {
   border-radius: 3px;
 }
 .noUi-connect {
   background: $accent-color;
 }
 /* Handles and cursors;
  */
 .noUi-draggable {
   cursor: ew-resize;
 }
 .noUi-vertical .noUi-draggable {
   cursor: ns-resize;
 }
 .noUi-handle {
   border: 1px solid $border-color;
   border-radius: 50%;
   background: $white;
   cursor: default;
   box-shadow: $box-shadow;
 }
 .noUi-active {
   box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #DDD, 0 3px 6px -3px #BBB;
 }
 /* Handle stripes;
  */
  .noUi-handle:before,
  .noUi-handle:after {
    content: "";
    display: block;
    position: absolute;
    height: 10px;
    width: 1px;
    background: $border-color;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
  }
  .noUi-horizontal .noUi-handle:before {
    margin-left: -2px;
  }
  .noUi-horizontal .noUi-handle:after {
    margin-left: 2px;
  }
 .noUi-vertical .noUi-handle:before,
 .noUi-vertical .noUi-handle:after {
   width: 10px;
   height: 1px;
 }
 .noUi-vertical .noUi-handle:before {
    margin-top: -2px;
  }
  .noUi-vertical .noUi-handle:after {
    margin-top: 2px;
  }
 /* Disabled state;
  */
 [disabled] .noUi-connect {
   background: $border-light;
 }
 [disabled].noUi-target,
 [disabled].noUi-handle,
 [disabled] .noUi-handle {
   cursor: not-allowed;
 }
 /* Base;
  *
  */
 .noUi-pips,
 .noUi-pips * {
   -moz-box-sizing: border-box;
   box-sizing: border-box;
 }
 .noUi-pips {
   position: absolute;
   color: #999;
 }
 /* Values;
  *
  */
 .noUi-value {
   position: absolute;
   white-space: nowrap;
   text-align: center;
 }
 .noUi-value-sub {
   color: #ccc;
   font-size: 10px;
 }
 /* Markings;
  *
  */
 .noUi-marker {
   position: absolute;
   background: #CCC;
 }
 .noUi-marker-sub {
   background: #AAA;
 }
 .noUi-marker-large {
   background: #AAA;
 }
 /* Horizontal layout;
  *
  */
 .noUi-pips-horizontal {
   padding: 10px 0;
   height: 80px;
   top: 100%;
   left: 0;
   width: 100%;
 }
 .noUi-value-horizontal {
   -webkit-transform: translate(-50%, 50%);
   transform: translate(-50%, 50%);
 }
//  .noUi-rtl .noUi-value-horizontal {
//    -webkit-transform: translate(50%, 50%);
//    transform: translate(50%, 50%);
//  }
 .noUi-marker-horizontal.noUi-marker {
   margin-left: -1px;
   width: 2px;
   height: 5px;
 }
 .noUi-marker-horizontal.noUi-marker-sub {
   height: 10px;
 }
 .noUi-marker-horizontal.noUi-marker-large {
   height: 15px;
 }
 /* Vertical layout;
  *
  */
 .noUi-pips-vertical {
   padding: 0 10px;
   height: 100%;
   top: 0;
   left: 100%;
 }
 .noUi-value-vertical {
   -webkit-transform: translate(0, -50%);
   transform: translate(0, -50%);
   padding-left: 25px;
 }
//  .noUi-rtl .noUi-value-vertical {
//    -webkit-transform: translate(0, 50%);
//    transform: translate(0, 50%);
//  }
 .noUi-marker-vertical.noUi-marker {
   width: 5px;
   height: 2px;
   margin-top: -1px;
 }
 .noUi-marker-vertical.noUi-marker-sub {
   width: 10px;
 }
 .noUi-marker-vertical.noUi-marker-large {
   width: 15px;
 }
 .noUi-tooltip {
   display: block;
   position: absolute;
   border: 1px solid #D9D9D9;
   border-radius: $tooltip-border-radius;
   background: $tooltip-bg;
   color: $tooltip-color;
   padding: $tooltip-padding-y $tooltip-padding-x;
   text-align: center;
   white-space: nowrap;
   font-size: $tooltip-font-size;
   opacity: 0;
   transition: all .3s ease;
  }
  .noUi-handle:hover .noUi-tooltip{
    opacity: $tooltip-opacity;
  }
 .noUi-horizontal .noUi-tooltip {
   -webkit-transform: translate(-50%, 0);
   transform: translate(-50%, 0);
   left: 50%;
   bottom: 120%;
 }
 .noUi-vertical .noUi-tooltip {
   -webkit-transform: translate(0, -50%);
   transform: translate(0, -50%);
   top: 50%;
   right: 120%;
 } 