/// Global Error Pages
/////////////////////////////
.nk-error{
    &-ld {
        padding: 2rem 1rem;
    }
    &-gfx{
        padding-bottom: 30px;
        max-width: 90%;
    }
    &-head{
        font-size: 160px;
        font-weight: $fw-bold;
        background: -webkit-linear-gradient($accent-color, $accent-dark);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        opacity: .9;
    }
    &-title{
        padding-bottom: 1rem;
    }
    &-text{
        font-size: 1rem;
    }
}

@include media-breakpoint-up(sm){
    .nk-error{
        &-lead{
            font-size: 200px;
        }
        &-gfx{
            padding-bottom: 60px;
            max-width: 520px;
        }
    }
}